.NavBar {
    text-align: left;
    text-align: start;
    background-color:var(--main-color);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: calc(10px + 2vmin);
    color: var(--text-color);
    padding:7px;
}
.link-no-padding{
    padding:0;
    margin:0;
}
.link-color{
    
    color:var(--main-color, .5);

}
.social-anim {
    height: 100%;
    width: 100%;
    background-color:red;
    max-width: 40rem;
    margin: 0 auto;
    display: block;
    overflow: hidden;
    transform: translateZ(0);
    text-align: center;
    opacity: 1
}