.fas.fa-2x{
    margin-top:8px;
    margin-left:1px;
    font-size:1.5em;
}
@media screen and (max-width: 1169px){
    .vertical-timeline-element--education .vertical-timeline-element-date, .vertical-timeline-element--work .vertical-timeline-element-date,.vertical-timeline-element--award .vertical-timeline-element-date{
        color:#000
    }
    .vertical-timeline-element--work.current .vertical-timeline-element-date{
        color:#FFF
    }
}
@media screen and (min-width: 1170px){
    .fas.fa-2x{
        margin-top:15px;
        margin-left:1px;
        font-size:2em;
    }
    .vertical-timeline-element-date{
        color:white;
    }
}
.vertical-timeline-element-content p,.vertical-timeline-element-content h1, .vertical-timeline-element-content h2{
    color:black;
}



.vertical-timeline-element--work .vertical-timeline-element-content {
    border-top: 4px solid #2196f3;
}
.vertical-timeline-element--education .vertical-timeline-element-content {
    border-top: 4px solid #e91e63;
}
.vertical-timeline-element--award .vertical-timeline-element-content {
    border-top: 4px solid rgb(206, 171, 0);
}

.vertical-timeline-element-date{
    text-align: start;
}
