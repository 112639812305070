  #dp,#ap,#vp,#ip,#d2p,#idotp,#mp,#a2p,#sp,#op,#np{
    fill: rgb(66, 191, 245);
  }
  #larp,#rarp,#slp{
    /*fill:#a7925a;*/
    fill:white;
  }#slp{
    /*fill:#a7925a;*/
    fill:rgba(255,255,255,.5);
    
  }
  #crp{
    fill:white;
  }
  
  .flashing{
    animation: flash 1.2s ease-in-out infinite
  }
  @keyframes flash{
    100%{
      opacity:1
    }
    50%{
      opacity:1
    }
    49%{
      opacity:0
    }
    0%{
      opacity:0
    }
  }