.App, body {
  text-align: center;
  background-color:var(--main-color);
  padding-bottom:30px;
}
:root{
  --main-color:#343a40;
  --text-color:#FFF;
  --brand-primary: rgb(134, 243, 247);/*#c4ff3c;*/
  /* --brand-primary: #3ce2ff; */
  --brand-secondary: rgba(255,255,255,0.5); 
}
.App-logo {
  animation: App-logo-spin infinite 1s linear;
  pointer-events: none;
}
.aboutme-keyword {
  font-family: 'Indie Flower', cursive;
  font-size: 29px;
  font-weight:bold;
}
.aboutsection{
  margin:0 9%;
}
.abouttextsection{
  margin-bottom:20px;
  line-height:33px;
}
.social-icon{
  margin:10px;
}
.App-header {
  background-color: var(--main-color);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

svg.fill-dark{
 
  /*fill: rgba(255,255,255,.5);
  stroke: rgba(255,255,255,.5);*/
  fill:var(--brand-primary);
}
svg.fill-dark .logo-d, svg.fill-dark .logo-m{
  fill:var(--brand-primary);
}
svg.fill-dark .logo-arrow-tl, svg.fill-dark .logo-arrow-br, svg.fill-dark .logo-slash{
  fill:var(--brand-secondary);
  stroke: var(--brand-secondary);
}
p, h1, h2, span{
  color: var(--text-color);
}

.jumbotron {
  background-image: url("./icons/dm.jpg");
  background-size: cover;
  min-height:300px;
  width:100%;
  background-position-y:80%;
  position:relative;
}
.jumboMask{
  font-size: 98px;
    font-weight: 100;
    background-color: rgba(0,0,0,0.2);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: start;
}
.jumboText{
  position: absolute;
  bottom: 0px;
  left: 50px;
  right: 50px;
}
@media screen and (min-width: 992px) {
  .jumbotron {
    min-height:400px;
  }
}
@media screen and (min-width: 1400px) {
  .jumboText {
    /* width:80% !important;
    margin:0 auto 50px; */
    left:150px;
    right:150px;
  } 
}
@media screen and (max-width: 852px) {
   .jumboMask {
    /* width:80% !important;
    margin:0 auto 50px; */
    text-align:center;
    left:0;
    right:0;
  } 

  .jumboText{
    font-size: 87px;
    font-weight: 200;
    top: 0;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
